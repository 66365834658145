.login {
    // background: #f7f7f7;
    // height: 100%;
    // position: absolute;
    // width: 100%;
    .form-holder {
      display: flex;
      justify-content: center;
      padding: 0.5rem 1rem;
  
      .login-form {
        margin-top: 7%;
        background: $white;
        border-radius: 8px;
        width: 35%;
        // box-shadow: $box-shadow;
        padding: 1.5rem;
        text-align: center;
        @include mobile() {
          width: 100%;
        }
  
        .title {
          font-family: "Avenir-Medium", sans-serif !important;
        }
  
        .ant-form-item-label {
          padding: 0;
        }
  
        input {
          height: 2.5rem;
          border-radius: 8px;
        }
  
        .password-input {
          height: 2.6rem;
          border-radius: 8px;
  
          input {
            height: 2rem;
            border-radius: unset;
          }
        }
  
        .submit-btn {
          // border-radius: 40px;
        //   background: $purple;
          color: $white;
          padding: 0.8rem 3rem 2.2rem;
          height: 2.5rem;
          outline: none !important;
          border-color: $white !important;
  
          &:hover,
          &:focus,
          &:active {
            outline: none !important;
            border-color: $white !important;
          }
        }
      }
    }
  }
  
  .main {
    .login-image {
      background-image: url("../../../src/Assets/log.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }
    .down-col {
        padding-bottom: 1.7rem;
    }
    .form-col {
      display: flex;
      flex-direction: column;
      gap: 4.57rem;
      padding: 7.74rem 6.65rem 5.17rem;
      @include miniTablet () {
        padding: 7.74rem 3.65rem 5.17rem;
      }
    //   @include small2ndmobile() {
    //     padding: 7.74rem 1.65rem 5.17rem;
    //   }
    }
    .login-header {
      color: #182337;
      font-family: "Avenir", sans-serif !important;
      font-size: 2.14106rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
    .forgot {
      margin-top: -1.2rem;
      text-align: right;
      color: #697a8d;
      font-family: "Avenir", sans-serif !important;
      font-size: 1.0705rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .login-form label {
      color: #182337;
      font-family: "Avenir", sans-serif !important;
      font-size: 1.169rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .form-login-button {
      margin-top: 2.69rem;
    }
    .login-input {
      color: #182337;
      border-radius: 0.53956rem;
      border: 0.719px solid #9ba9af;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      background: #fff;
      font-family: "Avenir", sans-serif !important;
      font-size: 0.81419rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      &::placeholder {
        // color: #9fa0bf;
      }
    }
  
    .login-password-input {
      font-family: "Avenir", sans-serif !important;
      font-size: 0.81419rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 0.53956rem;
      border: 0.719px solid #9ba9af;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      background: #fff;
      color: #5d6d73;
    }
  
    .vendor-password-input .ant-input-prefix {
      color: transparent;
    }
    .vendor-password-input .ant-input-prefix > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url("") no-repeat center center;
      background-size: contain;
    }
    .login-password-form label {
      color: #182337;
      font-family: "Avenir", sans-serif !important;
  
      font-size: 1.169rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .form-button {
      color: #fff;
      font-family: "Avenir", sans-serif !important;
      font-size: 1.40506rem;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.00813rem;
      border-radius: 1.00363rem;
      background: var(--byzpal-blue, #185cfe);
      display: flex;
      box-sizing: border-box;
      text-align: center;
      justify-content: center;
      width: 100%;
      padding: 1.500363rem 0rem;
      align-items: center;
    }
  }