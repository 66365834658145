.aside {
    background-color: $sideNavBackground;
    padding-top: 1.5rem;
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    max-width: 16.666%;
    gap: 11.3vh;
    .nav {
        display: flex;
        flex-direction: column;
        gap: 7.99rem;
        .field-below {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .7rem;
            border: none;
            width: 90%;
            margin: auto;
            cursor: pointer;
            padding: 0 2rem;
            height: 2rem;
            .field-link {
                color: $opacityWhite;
            }
        }
        .field-belowB {
            background-color: $listItemHover;
            width: 80%;
            padding: .5rem 0 .5rem .6rem;
            border-radius: .3rem;
        }
    }
    .icon-holder {
        background-color: $white;
        margin-right: 4rem;
        border: none;
        border-top-right-radius: 4rem;
        border-bottom-right-radius: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .3rem 2rem .6rem;
    }
    .unordered-lists {
         display: flex;
         flex-direction: column;
         gap: 2vh;
        list-style: none;
        .list-item {
            color: $opacityWhite;
        }
        .list-itemA {
            display: flex;
            height: 2rem;
            align-items: center;
            justify-content: flex-start;
            gap: .7rem;
            width: 90%;
            margin: auto;
            padding: 1.5em 1.5em;

            cursor: pointer; 
        }
        .list-itemAB {
            background-color: $listItemHover;
            border-radius: .3rem;
        }
        .icon {
            fill: #FFFFFF99;
            & :hover {transform: scale(.1)}
        }
    }
}
