.subproducts {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.625rem;
    border: 0.04375rem solid $tableborder;
    padding: 1rem 1.5rem 0rem;
    box-sizing: border-box;
    .top-pane {
      display: flex;
      justify-content: space-between;
      .counter ,
      .plus {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        .product-count {
          display: flex;
          gap: 0.2rem;
          align-items: center;
          border: none;
          .state {
              color: $userAsh;
              font-size: .875rem;
              font-weight: 400;
              line-height: 1.3125rem;
              font-family: 'Avenir', sans-serif !important;
          }
          .sub {
              color: #475367;
              font-size: .875rem;
              font-family: 'Avenir', sans-serif !important;
              font-weight: 600;
              line-height: 1.3125rem;
          }
        }
        .nav-search {
          padding:  .48rem .719375rem;
        }
        .filter, .edit {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          border: 1px solid #D0D5DD;
          padding: .48rem .719375rem;
          border-radius: .4rem;
          cursor: pointer;
          .state {
              color: #344054;
              font-size: .875rem;
              font-family: 'Avenir', sans-serif !important;
              font-weight: 400;
              line-height: 1.3125rem;
          }
          .sub {
            color: #344054;
            font-size: .875rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 600;
            line-height: 1.3125rem;
        }
        }
        .subtext {
          text-align: center;
          border: 1px solid #2B8FEC;
          padding: .35rem .45rem;
          border-radius: .4rem;
          cursor: pointer;
          .sub {
            color: #2B8FEC;
            font-size: .875rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 600;
            line-height: 1.3125rem;
        }
      }
        .highlight {
          display: flex;
          align-items: center;
          border: 1px solid #D0D5DD;
          border-radius: .4rem;
          .icon-a {
              border-right: 1px solid #D0D5DD;
              padding: .35rem;
              cursor: pointer;
              &:focus {
                  background-color: #F0F2F5;
              }
              &:hover {
                  background-color: #F0F2F5;
              }
              &:active {
                  background-color: #F0F2F5;
              }
          }
          .icon {
              border-left: 1px solid #D0D5DD;
              padding: .35rem;
              cursor: pointer;
              &:focus {
                  background-color: #F0F2F5;
              }
              &:hover {
                  background-color: #F0F2F5;
              }
              &:active {
                  background-color: #F0F2F5;
              }
          }
        }
      }
    }
    .product-list {
      overflow: hidden;
      .flewRap {
        display: flex;
        flex-flow: column;
        flex-wrap: nowrap;
        .card {
          border: .06rem solid #B0D8FF80;
          color: #353534;
          font-family: 'Avenir', sans-serif !important;
          font-size: 1.130625rem;
          font-weight: 600;
          line-height: 1.1479375rem;
          padding: .8rem;
          .meta {
              
          }
          .edit {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            margin: .5rem 0;
            .price {
              color: #696E80;
              font-weight: 500;
              font-size: .9425rem;
              line-height: 2.2625rem;
          }
          }
          .availability {
              display: flex;
              justify-content: space-between;
              .stock {
                  display: flex;
                  align-items: center;
                  gap: .5rem;
                  border: none;
                  .stock-val {
                      color: #000000B2;
                      font-weight: 500;
                      font-size: .744375rem;
                      line-height: .90125rem;
                  }
              }
          }
          .card-image {
            height: 9.3rem;
            object-fit: contain;
          }
        }
      }
    }
    .pagination {
        display: flex;
        justify-content: center;
        list-style: none;
    .label {
        display: flex;
        align-items: center;
        gap: .5rem;
        color: #344054;
        font-weight: 600;
        font-size: .818125rem;
        line-height: 1.18625rem;
        width: fit-content;
        font-family: 'Avenir', sans-serif !important;
    }
    .page-item {
        list-style: none;
        display: flex;
        align-items: center;
    }
    .page-link {
        padding: .5rem .8rem;
        font-weight: 400;
        border-radius: .350625rem;
        color: #98A2B3;
        &:hover {
            background-color: #E5F7FF;
        }
        &:focus {
            background-color: #E5F7FF;

        }
        &:active {
            background-color: #E5F7FF;

        }
    }
    .previuos {
        padding: .5rem;
        border: .058125rem solid  #D0D5DD;
        border-radius: .4675rem;
        &:hover {
            background-color: #ebeceff7;
        }
        &:focus {
            background-color: #ebeceff7;

        }
        &:active {
            background-color: #ebeceff7;

        }

    }
    .previous-link {
        list-style: none;
    }
    .next {
        padding: .5rem 1.3rem;
        border: .058125rem solid  #D0D5DD;
        border-radius: .4675rem;
        &:hover {
            background-color: #ebeceff7;
        }
        &:focus {
            background-color: #ebeceff7;

        }
        &:active {
            background-color: #ebeceff7;

        }

    }
    .next-link {
        list-style: none;
    }
    .break {
        list-style: none;
    }
    .break-link {
        padding: .7rem .1rem .5rem .1rem;
        display: flex;
        align-self: center;
        text-align: center;
        justify-self: center;
    }
    
}
  }
  
  
  