.addProducts {
    padding: 2.5rem 3rem;
    background-color: #FFF;
    border-radius: .5rem;

    .form-item label {
        color: $userAsh;
        font-weight: 500;
        font-size: 1rem;
        font-family: 'Avenir', sans-serif !important;
        line-height: normal;
        font-style: normal;
        
    }
    .form-put {
        border-radius: .3rem;
        border: .0763125rem solid $tableborder;
        font-family: 'Avenir', sans-serif !important;
        font-size: .8149rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        padding: .7rem;
        color: #182337;
    }
}
.form-select {
.ant-select-selector {
    color: #9FA0BF;
    font-size: .8rem;
    width: 100%;
    height: 2.7rem;
    border: .0763125rem solid $tableborder !important;

      
}
} 

.suBut {
    color: #FFF;
    font-size: 1rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 1.5rem 4.93369rem;
    align-items: center;
    margin-top: 2rem;
    border-radius: 0.53331rem;
    background: $sideNavBackground;
}