.earning-chart {
    height: 2rem;
    flex-grow: 2;
    background-color: #FFFFFF;
    border-radius: .625rem;
    border: .04375rem solid $dotAsh;
    padding: 1rem 1.5rem 0rem;
    overflow: auto;
    box-sizing: border-box;
            .earningchart-fieldset {
                border: none;
                display: flex;
                justify-content: space-between;
                .earningchart-header {
                    color: $textBlack;
                    font-weight: 600;
                    font-size: 1.0625rem;
                    line-height: 1.0625rem;
                }
                .chart-dropdown {
                    color: $dropdownText;
                    font-weight: 400;
                    letter-spacing: 0.5%;
                    font-size: .8125rem;
                    line-height: .9375rem;
                }
            }
}
