.overview {
    display: flex;
    // gap: 5rem;
    justify-content: space-between;
    height: auto;
    .overview-col-A, .overview-col-B {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .overview-col-A { 
        width: 48.55rem; 
        .overview-analytics {
            display: flex;
            gap: 1rem;
            flex-shrink: 0;
            .overview-fieldset-A, .overview-fieldset-B, .overview-fieldset-C {
                display: flex;
                flex-direction: column;
                border: none;
                border-radius: .625rem;
                .overview-h6 {
                    font-weight: 600;
                    font-size: .9375rem;
                    line-height: .9375rem;
                    margin: 1rem 0rem 0rem 1rem;
                    font-family: 'Avenir', sans-serif !important;
                }
                .overview-h3 {
                    font-weight: 700;
                    font-size: 2.059375rem;
                    line-height: 2.508125rem;
                    margin: 1rem 0rem 1.5rem 1rem;
                    font-family: 'Avenir', sans-serif !important;
                }
            }
            .overview-fieldset-A {
                background-color: $orangeCard;
                flex-grow:.5;
            }
            .overview-fieldset-B {
                background-color: $ashCard;
                flex-grow:.8;    
            }
            .overview-fieldset-C {
                background-color: $purpleCard;
                flex-grow:1;
            }
        }
        .overview-table {
            flex-grow: 1.3;
            background-color: #FFFFFF;
            height: 1.2rem;
            border-radius: .625rem;
            border: .04375rem solid $dotAsh;
            padding: 1rem 1.5rem 0rem;
            box-sizing: border-box;
            .table {
                .ant-table {
                    height: 9rem;                  
                  }
                  .ant-table-thead > tr > th {
                    height: .2px;
                    border: none;
                  }
                .ant-table-tbody > tr > td {
                    padding: .01rem;
                    border: none;        
                  }
            }
            .sale-table {
                border: none;
                display: flex;
                justify-content: space-between;
                .sale-header {
                    color: $textBlack;
                    font-weight: 600;
                    font-size: 1.0625rem;
                    line-height: 1.0625rem;
                    font-family: 'Avenir', sans-serif !important;;
                }
                .chart-dropdown {
                    color: $dropdownText;
                    font-weight: 400;
                    letter-spacing: 0.5%;
                    font-size: .8125rem;
                    line-height: .9375rem;
                    font-family: 'Avenir', sans-serif !important;
                }
            }
            .order-list {
                
            }
        }
    }
    .overview-col-B {   
                flex-grow: 2; 
            .recent-order {
                background-color: #FFFFFF;
                border-radius: .625rem;
                border: .04375rem solid $dotAsh;
                padding: 1rem 1.5rem 1.1rem;
                box-sizing: border-box;
                .orderchart-fielset {
                    border: none;
                    display: flex;
                    justify-content: space-between;
                    .orderchart-header {
                        color: $textBlack;
                        font-weight: 600;
                        font-size: 1.0625rem;
                        line-height: 1.0625rem;
                        font-family: 'Avenir', sans-serif !important;
                    }
                    .three-dots {
                        flex-grow: .02;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: none;
                        cursor: pointer;
                        .dot {
                            background-color: black;
                            width: .2rem;
                            height: .2rem;                  
                            border-radius: 50%;
                        }
                    }                    
                }
                .list-picture {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }           
        
    }
}
.name {
    border: none;
    color: $lightblack;
    font-weight: 600;
    font-size: .91rem;
    font-family: 'Avenir', sans-serif !important;
    line-height: 1.108125rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.point {
    border: none;
    color: $lightblack;
    font-weight: 600;
    font-size: .845rem;
    font-family: 'Avenir', sans-serif !important;
    line-height: 1.029375rem;
}
.price {
    color: $lightblack;
    font-weight: 600;
    font-size: .845rem;
    font-family: 'Avenir', sans-serif !important;
    line-height: 1.029375rem;
}
