.upload {
    text-align: center;
    border-radius: .5rem;
    // font-size: 1.1419rem;
    font-family: 'Avenir', sans-serif !important;
    // font-weight: 700;
    // line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    cursor: pointer;
    border: 1px dashed  #aaa;

    .div {
        display: flex;
        flex-direction: column;
        // gap: 1rem;
         padding: 1.5rem 0rem;
        .icon {

            .uploader {
                border: none;
            }
        }
        .worder {

        }
    }
}