.subtext {
    display: flex;
    flex-flow: column;
    gap: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.625rem;
    border: 0.04375rem solid $tableborder;
    padding: 1rem 1.5rem 0rem;
    box-sizing: border-box;
    .top-pane {
      display: flex;
      justify-content:flex-end;
      .subtext {
        background-color: #2072BF;
        text-align: center;
        border: 1px solid #2B8FEC;
        padding: .48rem .719375rem;
        border-radius: .4rem;
        cursor: pointer;
        .sub {
            color: #FDFDFD;
          font-size: .875rem;
          font-family: 'Avenir', sans-serif !important;
          font-weight: 600;
          line-height: 1.3125rem;
      }
    }
    }
    .product-list {
              overflow: hidden;
              .flewRap {
                display: flex;
                flex-flow: column;
                flex-wrap: nowrap;
                .card {
                  border: .10125rem solid #E4E7ECE5;
                  color: #353534;
                  text-align: center;
                  font-family: 'Avenir', sans-serif !important;
                  font-size: 1.130625rem;
                  font-weight: 600;
                  line-height: 1.1479375rem;
                  padding: .8rem;
                  cursor: pointer;
                  .meta {
                      
                  }
                  .edit {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: none;
                    margin: .5rem 0;
                    .price {
                      color: #696E80;
                      font-weight: 500;
                      font-size: .9425rem;
                      line-height: 2.2625rem;
                  }
                  }
                  .availability {
                      display: flex;
                      justify-content: space-between;
          
                      .stock {
                          display: flex;
                          align-items: center;
                          gap: .5rem;
                          border: none;
                          .stock-val {
                              color: #000000B2;
                              font-weight: 500;
                              font-size: .744375rem;
                              line-height: .90125rem;
                          }
                      }
                  }
                  .card-image {
                    height: 9.3rem;
                    object-fit: contain;
                  }
                }
              }
            }
            .pagination {
                display: flex;
                justify-content: center;
                list-style: none;
            .label {
                display: flex;
                align-items: center;
                gap: .5rem;
                color: #344054;
                font-weight: 600;
                font-size: .818125rem;
                line-height: 1.18625rem;
                width: fit-content;
                font-family: 'Avenir', sans-serif !important;
            }
            .page-item {
                list-style: none;
                display: flex;
                align-items: center;
            }
            .page-link {
                padding: .5rem .8rem;
                font-weight: 400;
                border-radius: .350625rem;
                color: #98A2B3;
                &:hover {
                    background-color: #E5F7FF;
                }
                &:focus {
                    background-color: #E5F7FF;

                }
                &:active {
                    background-color: #E5F7FF;

                }
            }
            .previuos {
                padding: .5rem;
                border: .058125rem solid  #D0D5DD;
                border-radius: .4675rem;
                &:hover {
                    background-color: #ebeceff7;
                }
                &:focus {
                    background-color: #ebeceff7;

                }
                &:active {
                    background-color: #ebeceff7;

                }

            }
            .previous-link {
                list-style: none;
            }
            .next {
                padding: .5rem 1.3rem;
                border: .058125rem solid  #D0D5DD;
                border-radius: .4675rem;
                &:hover {
                    background-color: #ebeceff7;
                }
                &:focus {
                    background-color: #ebeceff7;

                }
                &:active {
                    background-color: #ebeceff7;

                }

            }
            .next-link {
                list-style: none;
            }
            .break {
                list-style: none;
            }
            .break-link {
                padding: .7rem .1rem .5rem .1rem;
                display: flex;
                align-self: center;
                text-align: center;
                justify-self: center;
            }
            
        }
}

.subadmin-style .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
    padding: 0;
    .ant-modal-header {
        background-color: #F5F6FA;
        border-radius: .40706rem;
        padding: 1rem 1.5rem;
        width: 100%;
      }
      .ant-modal-body {
        padding: 1.5rem; 
      }
  }
 
  .subadmin-style .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.694375rem;
}

.subadmin-style .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

  .subadmin-style {
    .subadmin-form label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .subadmin-input {
        color: #182337;
        border-radius: 0.35rem;
        border: .0875rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.933125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.129375rem;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }

}
.subBut {
    color: #FFF;
    font-size: 0.933125rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.525rem 2.216875rem;
    align-items: center;
    margin-top: 2rem;
    border-radius: 0.233125rem;
    background-color: $sideNavBackground;
}
  
  