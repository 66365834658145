.products {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.625rem;
  border: 0.04375rem solid $tableborder;
  padding: 1rem 1.5rem 0rem;
  box-sizing: border-box;
  .top-pane {
    display: flex;
    justify-content: space-between;
    .counter,
    .plus {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      .product-count {
        display: flex;
        gap: 0.2rem;
        align-items: center;
        border: none;
        .state {
          color: $userAsh;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.3125rem;
          font-family: "Avenir", sans-serif !important;
        }
        .sub {
          color: #475367;
          font-size: 0.875rem;
          font-family: "Avenir", sans-serif !important;
          font-weight: 600;
          line-height: 1.3125rem;
        }
      }
      .nav-search {
        padding: 0.48rem 0.719375rem;
      }
      .filter,
      .add {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        border: 1px solid #d0d5dd;
        padding: 0.48rem 0.719375rem;
        border-radius: 0.4rem;
        cursor: pointer;
        .state {
          color: #344054;
          font-size: 0.875rem;
          font-family: "Avenir", sans-serif !important;
          font-weight: 400;
          line-height: 1.3125rem;
        }
        .sub {
          color: #fdfdfd;
          font-size: 0.875rem;
          font-family: "Avenir", sans-serif !important;
          font-weight: 600;
          line-height: 1.3125rem;
        }
      }
      .add {
        background-color: #2b8fec;
      }
      .subtext {
        text-align: center;
        border: 1px solid #2b8fec;
        padding: 0.35rem 0.45rem;
        border-radius: 0.4rem;
        cursor: pointer;
        .sub {
          color: #2b8fec;
          font-size: 0.875rem;
          font-family: "Avenir", sans-serif !important;
          font-weight: 600;
          line-height: 1.3125rem;
        }
      }
      .highlight {
        display: flex;
        align-items: center;
        border: 1px solid #d0d5dd;
        border-radius: 0.4rem;
        .icon-a {
          border-right: 1px solid #d0d5dd;
          padding: 0.35rem;
          cursor: pointer;
          &:focus {
            background-color: #f0f2f5;
          }
          &:hover {
            background-color: #f0f2f5;
          }
          &:active {
            background-color: #f0f2f5;
          }
        }
        .icon {
          border-left: 1px solid #d0d5dd;
          padding: 0.35rem;
          cursor: pointer;
          &:focus {
            background-color: #f0f2f5;
          }
          &:hover {
            background-color: #f0f2f5;
          }
          &:active {
            background-color: #f0f2f5;
          }
        }
      }
    }
  }
  .product-list {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding-bottom: 1rem;
    overflow: hidden;
    .flewRap {
      display: flex;
      flex-flow: column;
      flex-wrap: nowrap;
      // align-items: flex-end;
      width: fit-content;
      // border: 4px solid salmon;
      .pageOf {
        width: fit-content;
        // border: 4px solid salmon;
        // margin-top: 5rem;
        z-index: 5;
      }
      .card {
        border: 0.06rem solid #b0d8ff80;
        color: #353534;
        font-family: "Avenir", sans-serif !important;
        font-size: 1.130625rem;
        font-weight: 600;
        line-height: 1.1479375rem;
        padding: 1.5rem 0.8rem 0rem 0.8rem;
        .meta {
          margin-top: 0.3rem;
        }
        .edit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: none;
          margin-top: 0.2rem;
          .price {
            color: #696e80;
            font-weight: 500;
            font-size: 0.9425rem;
            line-height: 2.2625rem;
          }
          .two-icons {
            .delete {
              margin-left: 0.5rem;
            }
          }
        }
        .availability {
          display: flex;
          justify-content: space-between;
          margin-top: 0.2rem;
          .stock {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border: none;
            .stock-val {
              color: #000000b2;
              font-weight: 500;
              font-size: 0.744375rem;
              line-height: 0.90125rem;
            }
          }
        }
        .card-image {
          height: 8.3rem;
          object-fit: contain;
        }
      }
    }
  }

  .pagination {
    display: flex;
    // border: 2px solid brown;
    justify-content: center;
    // list-style: none;
    // background-color: #2b8fec;
    // max-width: 60rem;
    .previous,
    .next {
      display: flex;
      gap: 0.5rem;
      border: 0.058125rem solid #d0d5dd;
      border-radius: 0.4675rem;
      color: #344054;
      font-family: "Avenir", sans-serif !important;
      &:hover {
        background-color: #ebeceff7;
      }
      &:focus {
        background-color: #ebeceff7;
      }
      &:active {
        background-color: #ebeceff7;
      }
    }
    .previous {
      display: flex;
      padding: 0.1rem 1rem;
      align-self: flex-end;
      justify-self: end;
    }
    .next {
      padding: 0.1rem 1.75rem;
    }
    .page {
      // padding: 0.2rem 0.5rem;
      font-weight: 400;
      border-radius: 0.350625rem;
      font-family: "Avenir", sans-serif !important;
      color: #98a2b3;
      &:hover {
        background-color: #e5f7ff;
        color: #185CFE;
      }
      &:focus {
        background-color: #e5f7ff;
        color: #185CFE;
      }
      &:active {
        background-color: #e5f7ff;
        color: #185CFE;
      }
    }
    
    // .label {
    //   display: flex;
    //   align-items: center;
    //   gap: 0.1rem;
    //   color: #344054;
    //   font-weight: 600;
    //   font-size: 0.818125rem;
    //   line-height: 1.18625rem;
    //   width: fit-content;
      
    // }
    // .page-item {
    //   list-style: none;
    //   display: flex;
    //   align-items: center;
    // }
    
    // .break-link {
    //   padding: 0.7rem 0.1rem 0.5rem 0.1rem;
    //   display: flex;
    //   align-self: center;
    //   text-align: center;
    //   justify-self: center;
    // }
  }
}
