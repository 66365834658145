.navbar {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem 1.5rem;
  gap: 2rem;
  .nav-col_B {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .nav-header-div {
      width: 9rem;
    }
    .nav-sesarch-and-more { 
      display: flex;
      flex-grow: 0.7;
      gap: 25%;
      .nav-search {
        border-radius: 2.5rem;
        border: 0.07rem solid #e9e9e9;
        color: $searchAsh;
        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        background-color: #ffffff;
        max-width: 50rem;
      }
      .nav-div {
        display: flex;
        gap: 1rem;
        align-items: center;
        .nav-notification {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          padding: 0.5rem;
          border: 0.07rem solid #e9e9e9;
          border-radius: 0.3rem;
        }
        .nav-user {
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: #ffffff;
          border-radius: 1.875rem;
          padding: 0rem 1.3rem 0rem 0.3rem;
          .nav-fieldset {
            position: relative;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            border: none;
            .img-round-logo {
              position: absolute;
              left: 1.6rem;
              top: 1.7rem;
              border: 0.17rem solid black;
              width: 0.8rem;
              height: 0.8rem;
              background-color: rgba(94, 222, 26, 0.82);
              border-radius: 50%;
            }
          }
          .nav-user-header {
            width: max-content;
            text-align: center;
            box-sizing: border-box;
            color: $userAsh;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.05875rem;
          }
        }
      }
    }
  }
  .avbar-children {
    flex-grow: 2;
  }
}
