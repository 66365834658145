.order-chart {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background-color: #FFFFFF;
    border-radius: .625rem;
    border: .04375rem solid $dotAsh;
    padding: 1rem 1rem  1.5rem 1rem;
    // align-items: center;
    box-sizing: border-box;
    .orderchart-fielset {
        border: none;
        display: flex;
        justify-content: space-between;
        // .chart-div {
            // display: flex;
            // min-width: 16.87rem;
            // justify-content: space-between;
            .orderchart-header {
                color: $textBlack;
                font-weight: 600;
                font-size: 1.0625rem;
                line-height: 1.0625rem;
            }
            .three-dots {
                flex-grow: .02;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: none;
                cursor: pointer;
                .dot {
                    background-color: black;
                    width: .2rem;
                    height: .2rem;                  
                    border-radius: 50%;
                }
            }
        }
    .chart {
        display: flex;
        flex-direction: column;
    }
    }
