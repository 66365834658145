@mixin upDesktop() {
    @media (min-width: 992px) {
        @content ;
    }
}


@mixin downDesktop() {
    @media (max-width: 992px) {
        @content ;
    }
}


@mixin tabletRange() {
    @media (min-width: 768px) and (max-width: 992px) {
        @content ;
    }
}

@mixin tablet() {
    @media (min-width: 668px) and (max-width: 899px) {
        @content ;
    }
}


@mixin miniTablet() {
    @media (max-width: 615px) {
        @content ;
    }
}


@mixin mini-desktop() {
    @media (min-width: 1005px) and (max-width: 1215px) {
        @content ;
    }
}


@mixin mobile() {
    @media (max-width: 768px) {
        @content ;
    }
}


@mixin BigTablet() {
    @media (max-width: 1129px) {
        @content ;
    }
}


@mixin smallMobile() {
    @media (min-width: 375px) {
        @content ;
    }
}


@mixin smallMobileOne() {
    @media (max-width: 479px) {
        @content ;
    }
}


@mixin smallMobileTwo() {
    @media (max-width: 375px) {
        @content ;
    }
}

@mixin largeDestop() {
    @media (min-width: 1550px) {
        @content ;
    }
}





$sideNavBackground: #2072BF;
$earningChartGradient: linear-gradient(180deg, #1AE8CE 0%, rgba(26, 232, 206, 0.0001) 100%);
$orangeCard: #FCE3E3;
$ashCard:#E3EBFC;
$purpleCard:#E9E3FC;
$pageHeader:#182337;
$cardBlack:#000000;
$userAsh:#5D6D73;
$bellBlack:#292D32;
$searchAsh:#9FA4BF;
$dotAsh:#E9E9E9;
$lighterGreen:#DBF3FF;
$letteringAsh:#0000004D;
$lighterBlack:#1C1D1EE5;
$ash:#666666;
$yellow:#FEB721;
$white:#FFFFFF;
$opacityWhite:#FFFFFF99;
$green:#0F973D;
$lightRed:#EB5017;
$lightPink:#FFECE5;
$listItemHover: #FFFFFF26;
$textBlack: #182337;
$dropdownText: #1C1D1EE5;
$lightblack:#000000B2;
$tableborder:#E4E7EC;
$tagGreen:#27AE60;
$tagYellow:#F1B809;
$tagRed:#FF4B55;
$tagGbacground:#27AE600D;
$tagYbackground:#F4F2EC;
$tagRbackground:#F6EDED;
$subHeader:#101928;

