.customer {
  display: flex;
  flex-direction: column;
  .customer-top {
    display: flex;
    justify-content: space-between;
    .sub {
      color: subHeader;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.635rem;
    }
    .customer-sort {
      display: flex;
      .customer-search {
        border: none;
        width: 6rem;
        background-color: #faf7f7;
        color: $userAsh;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3125rem;
        ::placeholder {
            color: $userAsh !important;
            font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.3125rem;
        }
      }
      .filter {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: none;
        margin-left: 0.7rem;
        cursor: pointer;
        .state {
          color: $userAsh;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.3125rem;
        }
      }
      .sort {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: none;
        margin-left: 1.5rem;
        margin-right: 1.3rem;
        cursor: pointer;
        .state {
          color: $userAsh;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.3125rem;
        }
      }
    }
  }
  .customer-table {
    margin-top: 1.5rem;
    border: 0.0625rem solid $tableborder;
    border-radius: 0.625rem;
  }
}
.point {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
