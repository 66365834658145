.all-buttons {
    display: flex;
    gap: .52375rem;
 
.button-1, .button-2, .button-3, .button-4 {
    display: flex;
    color: #fff;
    font-size: .851rem;
    border-radius: .250625rem;
    border: none;
    gap: .24rem;
    align-items: center;
    font-family: 'Avenir', sans-serif !important;
}
.button-3 {
        
        background-color: #1DB88E;
        
    }

.button-4 {
        
        background-color: #FA1212;
        
    }
}

.vendorShadow .ant-modal-content {
    background-color: #fff;
    box-shadow: none;
    border-radius: .40706rem;
    width: 27.80588rem;
    height: auto;
    padding: 0;
    .ant-modal-body {
        padding: 1.5rem; 
      }
  }
  .vendorShadow  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
    padding: 1rem 1.5rem;
    width: 100%;
  }
  .ant-modal-header {
    background-color: #F5F6FA;
    border-radius: .40706rem;
    padding: 1rem 1.5rem;
    width: 100%;
  }

  .vendorShadow .ant-modal-title {
    font-family: 'Avenir', sans-serif !important;
    color: #182337;
    font-size: 1.42125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.vendorShadow .ant-modal-close-x {
      color: #182337;
      &:hover {
          opacity:.4;
      }  
}

.vendorShadow {
    .more-btn {
        margin-bottom: 1rem;
        border: none;
        color: #5D6D73;
        cursor: pointer;
    }
    .location-form label {
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }
    .location-input {
        color: #182337;
        border-radius: 0.35rem;
        border: .0875rem solid #E7E6F3;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.933125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.129375rem;
        text-transform: capitalize;
        &::placeholder {
            color:  #9FA0BF;
        }
    }
    .location-password ::placeholder {
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #5D6D73;
    }

    .location-password .ant-input-prefix {
        color: transparent;
    }
    .location-password .ant-input-prefix > span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: url('') no-repeat center center;
        background-size: contain;
    }
    .location-form-password label{
        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-size: 0.81419rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.locations {
    display: flex;
    flex-direction: column;



    .h-header {
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8125rem;
    }

    .add-locations {
        display: flex;
        align-items:center;
        justify-content: space-between;
        // @include miniTablet() {
        //     flex-direction: column;
        //     align-items: flex-start;
        //     gap: 1.5rem;
        // }
        .inputAndIcon {
            display: flex;
            max-width: 21.75rem;
            align-self:flex-start;
            padding: 0.5rem 1.3125rem 0.5rem 1rem ;
            border: .0625rem solid #E7E6F3; 

        }

        .addLocation {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: $sideNavBackground;
            padding: 1.1625rem 2.375rem;
            font-family: 'Avenir', sans-serif !important;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.375rem;
            letter-spacing: 0.01rem;
            border-radius: 0.25rem;
            margin-bottom: 1.57rem;
            &:hover {
                background-color: $sideNavBackground;
                color: #FFF;
            }
            // @include small2ndmobile() {
            //     padding: .4rem 1rem;
            //     font-size: .5rem;
            // }
        }
    }

    

    
    .custo {

        color: #5D6D73;
        font-family: 'Avenir', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.3125rem;
        // @include tabler() {
        //     font-size: .62rem;
        //     line-height: .8125rem;
        // }
        // @include mobile() {
        //     font-size: .6rem;
        //     line-height: .8125rem;
        // }
    }
    
    .ant-table-thead .ant-table-cell {
        background-color: #FFF;
      }
        .rowClassName {
            color: #152C5B;
            font-size: .875rem;
            font-family: 'Avenir', sans-serif !important;
            font-weight: 500;
            line-height: 1.05875rem;
            // @include mobile() {
            //     font-size: .6rem;
            // }
        }
    
        .for-blue{
            background-color: #185CFE;
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 3rem;
            padding: 1rem;
            border-radius: .25rem;
            // @include smallmobileA() {
            //     gap: .5rem;
            //     font-size: 1.5rem;
            //     padding: .7rem;
            // }
        }
        .for-light{
            background-color: #188ff71a;
            display: flex;
            align-items: center;
            gap: 1rem;
            font-size: 3rem;
            padding: 1rem;
            border-radius: .25rem;
            // @include smallmobileA() {
            //     gap: .5rem;
            //     font-size: 1.5rem;
            //     padding: .7rem;
                
            // }
        }
    .location-pagination {
        // @include smallmobileA() {
        //     display: flex;
        //     justify-content: center; 
        // }
        
        .pagination-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 2.75rem;
            gap: 1.75rem;
            list-style: none;
            font-family: 'Avenir', sans-serif !important;
            font-style: normal;
            font-weight: 500;
            font-size: .875rem;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.0125rem;
            width: auto;
    
            & li> a{
                color: #28293D;
            }
            // @include smallmobileA() {
            //     justify-content: center;
            //     gap: .3rem;
            //     padding-right: 2.5rem;
            // }
        }
    }
    

    // .ant-table-thead > tr > th {
    //     border-left: 3px solid #8d3030;
    //   }

    .location-table {
        overflow-x: auto;
    }

    .location-table .ant-table {
        // @include mobile() {
        //     width: 100%;
        // }
        // @include smallmobile() {
        //     width: 100%;
        //     overflow-x: auto;

        // }
       
    }

}

.submitBut {
    color: #FFF;
    font-size: 0.81419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 600;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    display: inline-flex;
    padding: 0.458rem 1.93369rem;
    align-items: center;
    gap: 0.50888rem;
    border-radius: 0.23331rem;
    background-color: $sideNavBackground;

    &:hover {
        background-color: red;
        color: #FFF;
    }
}