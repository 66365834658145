.uploader {
    text-align: center;
    border-radius: .5rem;
    font-size: .8419rem;
    font-family: 'Avenir', sans-serif !important;
    font-weight: 500;
    line-height: 1.1195rem;
    letter-spacing: 0.00813rem;
    cursor: pointer;
    border: 1px dashed  #aaa;
    display: flex;
    justify-content: center;
    .main {
        display: flex;
        align-items: center;
        gap: .6rem;
        flex-direction: column ;
        
        
    }
}