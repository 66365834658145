.order {
    display: flex;
    flex-direction: column;
    .order-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .order-state {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 22rem;
            .state {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $userAsh;
                font-size: .875rem;
                font-weight: 400;
                height: 2rem;
                line-height: 1.3125rem;
                cursor: pointer;
                &:hover, &:focus, &:active {
                    color: #2072BF;;
                    border-bottom: .125rem solid #2072BF;
                    padding-bottom: .2rem;
                }
               
            }
        }
        .order-sort {
            display: flex;
            align-items: center;       
            .order-search {
                border: none;
                width: 6rem;
                background-color:#faf7f7;
                color: $userAsh;
                font-size: .875rem;
                font-weight: 400;
                line-height: 1.3125rem;
                ::placeholder {
                    color: $userAsh !important;
                    font-size: 0.875rem;
                  font-weight: 400;
                  line-height: 1.3125rem;
                }
            }
            .filter {
                display: flex;
                align-items: center;
                gap: .5rem;
                border: none;
                margin-left: .7rem;
                cursor: pointer;
                .stater {
                    padding-top: .4rem;
                    color: $userAsh;
                    font-size: .875rem;
                    font-weight: 400;
                    line-height: 1.3125rem;   
                }
            }
            .sort {
                display: flex;
                align-items: center;
                gap: .5rem;
                border: none;
                margin-left: 1.5rem;
                margin-right: 1.3rem;
                cursor: pointer;
                .stater {
                    padding-top: .4rem;
                    color: $userAsh;
                    font-size: .875rem;
                    font-weight: 400;
                    line-height: 1.3125rem;
                }
            }
        }
    }
    .order-table {
        margin-top: 1.5rem;
        border: .0625rem solid $tableborder;
        border-radius: .625rem;
    }
}
.tags {
    display: flex;
    flex-direction: column;
    .tag-a, .tag-b, .tag-c {
        width: fit-content;
        border: none;
        border-radius: 1.500625rem;
    }
    .tag-a {
        color: $tagGreen;
        background-color: $tagGbacground;
    }
    .tag-b {
        color: $tagYellow;
        background-color: $tagYbackground;
    }
    .tag-c {
        color: $tagRed;
        background-color: $tagRbackground;
    }
}